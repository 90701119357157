export const ONE_SECOND = 1000;

export const ONE_MINUTE = 60 * ONE_SECOND;

export const ONE_HOUR = 60 * ONE_MINUTE;

export const ONE_DAY = 24 * ONE_HOUR;

export const timer = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};
